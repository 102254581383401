import { useEffect, useState } from "react";
import {connectWallet, getCurrentWalletConnected, mintNFT, bundleMintNFT, updateOptions } from "./utils/interact.js";
import React, { Component } from 'react';
import Select from 'react-select';
import detectEthereumProvider from '@metamask/detect-provider';
import { Container } from '@mui/material';


const Minter = (props) => {

  // const targetNetwork = '0x4'; // rinkeby chainId
  const targetNetwork = '0x1'; // mainnet chainId

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [seriesType, setSeriesType] = useState("");
  const [allowBundle, setAllowBundle] = useState(false);
  const [value, setValue] = useState(null);

  const handleChange = (value) => {
    try{
      console.log("Selected series type: " + value.value);
    } catch(e){
      console.log("Selected series type: " + value);
    }
    setValue(value);
    setSeriesType(value);
  };

  // React Selection
  const [options, setOptions] = useState([
    { id: 0 , value: 1, label: "Wæter (Vanilla, Cinnamon)", color: "#00B8D9", isDisabled: false},
    { id: 1 , value: 2, label: "Aeris (Bitter, Dark Chocolate)", color: "#0052CC", isDisabled: false},
    { id: 2 , value: 3, label: "Ignis (Spicy)", color: "#5243AA", isDisabled: false},
    { id: 3 , value: 4, label: "Fulmen (Sour Lemon, Sulphur)", color: "#FF5630", isDisabled: false},
    { id: 4 , value: 5, label: "Lux (Sweet, Honey)", color: "#FF8B00", isDisabled: false},
    { id: 5 , value: 6, label: "Kaos (Peat, Smoky)", color: "#FFC400", isDisabled: false},
    { id: 6 , value: 7, label: "Repititio (Floral)", color: "#36B37E", isDisabled: false},
    { id: 7 , value: 8, label: "Stella (Creamy, Rich, Full Bodied)", color: "#00875A",isDisabled: false}
  ]);

  // checks if the user is operating in the mainnet
  // const disableMainnet = () => {
  //   const chainId = provider.chainId;
  //   console.log("chain id: "+chainId);
  //   if (chainId === '0x1'){
  //     console.log('!!! operating on the mainnet !!!')
  //     setAllowBundle(false);
  //     setOptions([])
  //     setStatus("This is a test app, you are operating on the Mainnet!!!")
  //   }
  // }

  // checks single minting allowance for the connected wallet 
  const checkAddressOptions = async( walletAddress ) => {
    setStatus("Checking address minting allowances...")
    setAllowBundle(false); // on changing wallet, disable bundle mint
    setValue(null);
    setSeriesType("");// clear selection menu
    const optionsUpdates = await updateOptions(walletAddress);
    setOptions(optionsUpdates);
    for (let i = 0; i < 8; i++) {
      if (optionsUpdates[i].isDisabled) {
        return; // if any given series limit has been reached, bundle mint remains disabled
      }
    }
    setAllowBundle(true); // if non of the series limit has been exceeded, enable bundle mint for the wallet address
    // disableMainnet();
  };  
  

  const detectEthereumNetwork = async () => {
    // console.log("detectEthereumNetwork triggered")
    const provider = await detectEthereumProvider();
    const chainId = provider.chainId;
    if (chainId !== targetNetwork){
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: targetNetwork }],
        });
        await checkAddressOptions(provider.selectedAddress);
        // console.log("checkAddressOptions within detectEthereumNetwork trigggered - following target network change")
        setStatus("👆🏽 Choose from the above minting options.")
      } catch (e){
        setAllowBundle(false); // on changing wallet, disable bundle mint
        setValue(null);
        setOptions([]);
        console.error(e);
        setStatus("😥 Please double check that you are operating on the correct network or try refreshing the page");
      }
    } else {
      // console.log("checkAddressOptions within detectEthereumNetwork trigggered - no network change")
      try{
        await checkAddressOptions(provider.selectedAddress); // handle empty address
        setStatus("👆🏽 Choose from the above minting options.")
      } catch (err) {
        setStatus("Connect your metamask wallet to get started. provider.selectedAddress: " + provider.selectedAddress + " ChainId: " + chainId )
      }
    }
  }

  // detects when user changes metamask wallet (but not network)
  async function addWalletListener() {
    const provider = await detectEthereumProvider();
    if (provider) {
      provider.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          console.log("accountsChanged triggered")
          setValue(null);
          setSeriesType("");
          setWallet(accounts[0]);
          setStatus("👆🏽 Choose from the above minting options.");
          detectEthereumNetwork();
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
        
        
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel='noreferrer' href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  useEffect(async () => {
    // console.log("useEffect fired")
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    setValue(null);
    setSeriesType("");
    addWalletListener();
    setAllowBundle(false);
    await detectEthereumNetwork();
  }, []);


  const connectWalletPressed = async () => {
    const provider = await detectEthereumProvider();
    await provider.request({
      method: 'eth_requestAccounts', // wallet_requestPermissions - This RPC method is not yet available in MetaMask Mobile
      params: [{
        eth_accounts: {},
      }]
    });
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    detectEthereumNetwork();
  };

  const onBundleMintPressed = async () => {
    const provider = await detectEthereumProvider();
    const chainId = provider.chainId;
    if (chainId !== targetNetwork){
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: targetNetwork }],
        });
        await checkAddressOptions(provider.selectedAddress);
        setStatus("👆🏽 Choose from the above minting options.")
      } catch (e){
        setAllowBundle(false); // on changing wallet, disable bundle mint
        setValue(null);
        setOptions([]);
        console.error(e);
        setStatus("😥 Please double check that you are operating on the correct network");
      }
    } else {
      const { status } = await bundleMintNFT();
      setStatus(status);
    }
    
  };

  const onMintPressed = async () => {
    const provider = await detectEthereumProvider();
    const chainId = provider.chainId;
    if (chainId !== targetNetwork){
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: targetNetwork }],
        });
        await checkAddressOptions(provider.selectedAddress);
        setStatus("👆🏽 Choose from the above minting options.")
      } catch (e){
        setAllowBundle(false); // on changing wallet, disable bundle mint
        setValue(null);
        setOptions([]);
        console.error(e);
        setStatus("😥 Please double check that you are operating on the correct network");
      }
    } else {
      console.log(seriesType.value);
      const { status } = await mintNFT(seriesType.value);
      setStatus(status);
    }
  };

  return (
    <div className="Minter">
      {/* <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button> 
      <br></br>
      */}

      
      <h1 id="title">THE REBIRTH NFT MINTER</h1>
        
        {/* <Container maxWidth='false' disableGutters="true">
          <a href="https://www.ceilidhswhisky.com/the-rebirth.html"
            className="Whiskira Banner">
          <img alt='Whiskira Banner'
            src={require("./images/whiskira-minting-banner-v2.png").default} 
            resizeMode={'cover'}
            style={{ width: '100%'}}
          />
          </a>
        </Container> */}

        {/* <h2 id="promotion">Special Promotion Period Ending March 31st, 2022</h2>
          <ul id="promotion-content">
            <li>Receieve a Whiskira gift set for each NFT minted: Whiskira Signature Set (4 Glasses)</li>
            <li>Special Whiskira reward for bundle minting (8 NFTs minted): 2 Golden Whisky Decanters + 8 Golden Whisky Tumblers in four different styles</li>
            <li>Fill out claim form on <a href="https://discord.com/invite/eywsBGHsda" target="_blank" rel="noreferrer" color="Aquamarine">Discord</a> in order to recieve your promotion gifts</li>
            <li>Level up on <a href="https://discord.com/invite/eywsBGHsda" target="_blank" rel="noreferrer" color="Aquamarine">Discord</a> to recieved addtional rewards: metaverse wearables airdrops (stay tuned for sneak peek)</li>
            <li>Reach out to us on <a href="https://discord.com/invite/eywsBGHsda" target="_blank" rel="noreferrer" color="Aquamarine">Discord</a> for customer support</li>
            <li>Read more about the Rebirth project activities on our <a href="https://medium.com/the-rebirth" target="_blank" rel="noreferrer" color="Aquamarine">Medium</a> blog</li>
          </ul> */}

      <h2>Minting Information</h2>
      <ul>
        <li>There are 8 cults in the Rebirth Collection</li>
        <li>One set contains 1 NFT from each of the 8 cults (1 set = 8 NFTs)</li>
        <li>Each address is limited to minting 16 NFTs (or 2 complete sets of 8)</li>
        <li>Each address is limited to minting 2 of each cults </li>
        <li>Addresses may mint by the set or by singles</li>
        <li>Single mint = Minting 1 NFT @ 0.2Ξ</li>
        <li>Bundle mint = Minting 1 set (8 NFTs) @ 1.6Ξ</li>
        <li>Both Single + Batch mint now available</li>
      </ul>

        
      <br></br>  
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
      
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <h2>Bundle mint = Minting 1 set (8 NFTs) @ 1.6Ξ </h2>
 
      <button id="bundleMintButton" 
        onClick={onBundleMintPressed}
        disabled={!allowBundle}
        >
        Bundle Mint (1.6Ξ)
      </button>

      <br></br>
      <br></br>
      <br></br>

      <h2>Single mint = Minting 1 NFT @ 0.2Ξ</h2>
        <p>Select the cult you wish to mint</p>

    
      <Select 
        options={options} 
        isClearable 
        isDisabled={options.isDisabled}
        placeholder="Select cult to mint..."
        onChange={handleChange}
        value = {value}
        />
      

      <button id="mintButton" 
        onClick={onMintPressed}
        disabled={!seriesType || walletAddress === ""}
      >
        Single Mint (0.2Ξ)
      </button>
      
      {/* <form>
        <h2>🖼 Link to asset: </h2>
        <input
          type="text"
          placeholder="e.g. https://gateway.pinata.cloud/ipfs/<hash>"
          onChange={(event) => setURL(event.target.value)}
        />
        <h2>🤔 Name: </h2>
        <input
          type="text"
          placeholder="e.g. My first NFT!"
          onChange={(event) => setName(event.target.value)}
        />
        <h2>✍️ Description: </h2>
        <input
          type="text"
          placeholder="e.g. Even cooler than cryptokitties ;)"
          onChange={(event) => setDescription(event.target.value)}
        />
      </form> */}

      <p id="status">
        {status}
      </p>
    </div>
  );
};

export default Minter;
