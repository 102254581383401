import React from "react";
import { FaDiscord,FaInstagram,FaTwitter,FaMedium } from "react-icons/fa"
import { SiLinktree } from "react-icons/si"


export default function SocialFollow() {
  return (
    <div class="social-container">
      {/* <h2>Social Media</h2> */}
      <a href="https://discord.com/invite/eywsBGHsda"
        className="Discord social">
        <FaDiscord size="2em" color="black"/>
      </a>
      <a href="https://www.instagram.com/the.rebirth.nft/"
        className="Instagram social">
        <FaInstagram size="2em" color="black"/>
      </a>
      <a href="https://www.twitter.com/TheRebirthNFT" className="Twitter social">
        <FaTwitter size="2em" color="black"/>
      </a>
      <a href="https://medium.com/the-rebirth"
        className="Medium social">
        <FaMedium size="2em"  color="black"/>
      </a>
      {/* <a href="https://linktr.ee/TheRebirthNFT"
        className="Linktree social">
        <SiLinktree size="2em"  color="lightgrey"/>
      </a> */}
      <a href="https://opensea.io/collection/the-rebirth-nft"
        className="Opensea social">
        <img
          alt="opensea logo"
          src={require("./images/opensea.png").default} 
          style = {{height: 32, width: 32 , color: "black"}}
          />
      </a>
</div>
  );
}