import './App.css';
import Minter from './Minter'
import SocialFollow from "./SocialFollow"
import * as React from 'react';
import { Container } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';

// const background = require('./images/factory.png');

function App() {
  return (
    <div className="App">
      {/* <Container maxWidth='false' disableGutters="true">
      <a href="https://www.the-rebirth.com"
        className="Homepage Redirect">
      <img alt='Background'
        src={require("./images/whiskira-minting-banner-v5.png").default} 
        resizeMode={'cover'}
        style={{ width: '100%'}}
      />
      </a>
      </Container> */}
      <Minter></Minter>
      
      <Container maxWidth='false' disableGutters="true">
        <SocialFollow></SocialFollow>
      </Container>
      

      
      {/* <Container maxWidth='false' disableGutters="true">
      <img alt='Background'
        src={require("./images/footer.png").default} 
        resizeMode={'cover'}
        style={{ width: '100%'}}
      />
      </Container> */}

    </div>
  );
}

export default App;
