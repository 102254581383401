// require('dotenv').config();
// // const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY_RINKEBY;
// const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY_MAINNET;
// const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
// export const web3 = createAlchemyWeb3(alchemyKey);
var Web3 = require('web3');
const web3 = new Web3(window.ethereum);

const contractABI = require('../contract-abi.json')
// const contractAddress = "0x3c6A5BdE358aDE2e721370b18f750f3f52b9184a"; // rebirth rinkeby address
const contractAddress = "0x863a96138dEd057F254e269516BC31cf00dF2c92"; // rebirth mainnet address



export const updateOptions = async(walletAddress) => {
  
  var updates = [
    { id: 0 , value: 1, label: "Wæter (Vanilla, Cinnamon)", color: "#00B8D9", isDisabled: false},
    { id: 1 , value: 2, label: "Aeris (Bitter, Dark Chocolate)", color: "#0052CC", isDisabled: false},
    { id: 2 , value: 3, label: "Ignis (Spicy)", color: "#5243AA", isDisabled: false},
    { id: 3 , value: 4, label: "Fulmen (Sour Lemon, Sulphur)", color: "#FF5630", isDisabled: false},
    { id: 4 , value: 5, label: "Lux (Sweet, Honey)", color: "#FF8B00", isDisabled: false},
    { id: 5 , value: 6, label: "Kaos (Peat, Smoky)", color: "#FFC400", isDisabled: false},
    { id: 6 , value: 7, label: "Repititio (Floral)", color: "#36B37E", isDisabled: false},
    { id: 7 , value: 8, label: "Stella (Creamy, Rich, Full Bodied)", color: "#00875A",isDisabled: false}
  ];
  console.log("updating options for wallet: " + walletAddress);
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);//loadContract();

  if (walletAddress.length > 0) {
    for (let i = 0; i < 8; i++) {
      await window.contract.methods.seriesPerAddressCounter(walletAddress,i)
      .call()
      .then(res => {
        console.log("Series type " + i +": "+ res);
        if (res === '2') {
          updates[i].isDisabled = true;
        };
      })
      .catch(err => {
        console.log(err);
      })
    };
  }
  
  return updates;
} 

export const bundleMintNFT = async() => {

    //load smart contract
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);//loadContract();
    console.log("bundleMintNFT send transaction data: " + window.contract.methods.bundleMint().encodeABI())
  //   web3.eth.getGasPrice().then(gasPrice => {
  //     console.log('gasPrice = ' + gasPrice);
  //  });

  //  console.log("Gas to hex: "+ web3.utils.numberToHex('1500000'));

    const transactionParameters = {
        gas: web3.utils.numberToHex('1500000'), //'0x16e360'
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: '0x16345785d8a00000',
        'data': window.contract.methods.bundleMint().encodeABI(), //make call to NFT smart contract - 1.6 ETH
        chainId: '0x1',
    };

    //sign transaction via Metamask
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
}


export const mintNFT = async(seriesType) => {

    //error handling - single mint take in a valid series type
    if (!Number.isInteger(seriesType) || seriesType < 1 || seriesType > 8) { 
        return {
            success: false,
            status: "❗Please make sure the series type is an integer between 1 and 8.",
        }
    } else if (seriesType == null){
      return {
        success: false,
        status: "Please select a subseries type to mint."
      }
    }

    // spirits = ["Wæter","Aeris","Ignis","Fulmen","Lux","Kaos","Repititio","Stella"]

    //load smart contract
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);//loadContract();
    console.log("mintNFT send transaction data: " + window.contract.methods.mint(seriesType).encodeABI());
  //   web3.eth.getGasPrice().then(gasPrice => {
  //     console.log('gasPrice = ' + gasPrice);
  //  });
   

    //set up your Ethereum transaction
    const transactionParameters = {
        gas: web3.utils.numberToHex('230000'), //'0x16e360'
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: '0x2c68af0bb140000',
        'data': window.contract.methods.mint(seriesType).encodeABI(), //make call to NFT smart contract - 
        chainId: '0x1',
    };

    //sign transaction via Metamask
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
}


export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Choose from the above minting options.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" rel='noreferrer' href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };


  async function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Choose from the above minting options.",
        };
      } else {
        return {
          address: "",
          status: '🦊 Connect to Metamask using the "Connect Wallet" button.',
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" rel='noreferrer' href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  }

  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      return await handleEthereum();
      // try {
      //   const addressArray = await window.ethereum.request({
      //     method: "eth_accounts",
      //   });
      //   if (addressArray.length > 0) {
      //     return {
      //       address: addressArray[0],
      //       status: "👆🏽 Choose from the above minting options.",
      //     };
      //   } else {
      //     return {
      //       address: "",
      //       status: "🦊 Connect to Metamask using the top right button.",
      //     };
      //   }
      // } catch (err) {
      //   return {
      //     address: "",
      //     status: "😥 " + err.message,
      //   };
      // }
    } else {
      window.addEventListener('ethereum#initialized', handleEthereum, {
        once: true,
      });
    
      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds
    
      // return {
      //   address: "",
      //   status: (
      //     <span>
      //       <p>
      //         {" "}
      //         🦊{" "}
      //         <a target="_blank" rel='noreferrer' href={`https://metamask.io/download.html`}>
      //           You must install Metamask, a virtual Ethereum wallet, in your
      //           browser.
      //         </a>
      //       </p>
      //     </span>
      //   ),
      // };
    }
  };

